import { css } from '@emotion/react'
import type { InvoicingProcessAction } from '@orus.eu/backend/src/invoicing/stores/invoicing-process-run-event'
import { ContentContainerBackoffice, Text, colors, spacing } from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { Link } from '@tanstack/react-router'
import { memo, type ReactNode } from 'react'
import { trpcReact } from '../../../../client'
import { formatDateTimeSeconds } from '../../../../lib/format'
import { BackofficeSectionTitle } from '../../../atoms/backoffice-section-title'

const PlatformInvoiceProcessActionsPage = memo(function PlatformInvoiceProcessActionsPage() {
  const [{ runs, actions }] = trpcReact.invoices.getInvoicingRunsReport.useSuspenseQuery()

  return (
    <ContentContainerBackoffice marginTop={spacing[70]}>
      <Header title="Invoicing process recent runs" />

      <Table
        css={css`
          margin-top: ${spacing[70]};
        `}
      >
        <thead>
          <tr>
            <th>Run ID</th>
            <th>Started at</th>
            <th>Ended at</th>
            <th>Generated</th>
            <th>Validated</th>
            <th>Paid</th>
            <th>Failed payments</th>
            <th>Crashes</th>
          </tr>
        </thead>
        <tbody>
          {runs.map(
            (
              { runId, startedAt, endedAt, generatedCount, validatedCount, paidCount, failedPaymentCount, crashCount },
              index,
            ) => (
              <tr key={index}>
                <td>
                  <Text element="span">{runId}</Text>
                </td>
                <td>
                  <Text element="span">{formatDateTimeSeconds(startedAt)}</Text>
                </td>
                <td>
                  <Text element="span">{formatDateTimeSeconds(endedAt)}</Text>
                </td>
                <td>
                  <Text element="span">{generatedCount}</Text>
                </td>
                <td>
                  <Text element="span">{validatedCount}</Text>
                </td>
                <td>
                  <Text element="span">{paidCount}</Text>
                </td>
                <td>
                  <Text element="span">{failedPaymentCount}</Text>
                </td>
                <td>
                  <Text element="span">{crashCount}</Text>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </Table>

      <BackofficeSectionTitle>Invoicing process recent actions</BackofficeSectionTitle>

      <Table
        css={css`
          margin-top: ${spacing[70]};
        `}
      >
        <thead>
          <tr>
            <th>Run ID</th>
            <th>Type</th>
            <th>Time</th>
            <th>Outcome</th>
            <th>User ID</th>
          </tr>
        </thead>
        <tbody>
          {actions.map(({ runId, type, time, outcome, userId }, index) => (
            <tr
              key={index}
              css={css`
                background-color: ${colorByOutcome[outcome]};
              `}
            >
              <td>
                <Text element="span">{runId}</Text>
              </td>
              <td>
                <Text element="span">{type}</Text>
              </td>
              <td>
                <Text element="span">{outcome}</Text>
              </td>
              <td>
                <Text element="span">{formatDateTimeSeconds(time)}</Text>
              </td>
              <td>
                <Link to="/bak/users/$userId" params={{ userId }}>
                  <Text element="span">{userId}</Text>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </ContentContainerBackoffice>
  )
})
export default PlatformInvoiceProcessActionsPage

const colorByOutcome: Record<InvoicingProcessAction['outcome'], string> = {
  success: 'transparent',
  failure: colors.yellow[200],
  'unsupported-negative-amount': colors.yellow[200],
  'zero-amount': 'transparent',
  'no-payment-method': colors.yellow[200],
  'obsolete-payment-method': colors.yellow[200],
  'payment-disabled-ongoing-incident': colors.yellow[200],
  'provider-error': colors.yellow[200],
  crash: colors.red[200],
}

type TableProps = { children: ReactNode; className?: string }
const Table = memo<TableProps>(function Table({ children, className }) {
  return (
    <table
      className={className}
      cellSpacing="0"
      css={css`
        & th {
          text-align: left;
        }

        & th,
        & td {
          &:not(:first-of-type) {
            padding-left: ${spacing[70]};
          }
        }

        & tbody {
          & tr {
            &:hover {
              outline: 1px solid ${colors.gray[500]};
            }
          }
        }
      `}
    >
      {children}
    </table>
  )
})
